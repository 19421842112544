// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-listing-js": () => import("./../../../src/pages/businessListing.js" /* webpackChunkName: "component---src-pages-business-listing-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newclassified-js": () => import("./../../../src/pages/newclassified.js" /* webpackChunkName: "component---src-pages-newclassified-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/articleList.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-business-list-js": () => import("./../../../src/templates/businessList.js" /* webpackChunkName: "component---src-templates-business-list-js" */),
  "component---src-templates-business-listing-js": () => import("./../../../src/templates/businessListing.js" /* webpackChunkName: "component---src-templates-business-listing-js" */),
  "component---src-templates-classified-js": () => import("./../../../src/templates/classified.js" /* webpackChunkName: "component---src-templates-classified-js" */),
  "component---src-templates-classified-list-js": () => import("./../../../src/templates/classifiedList.js" /* webpackChunkName: "component---src-templates-classified-list-js" */),
  "component---src-templates-notice-js": () => import("./../../../src/templates/notice.js" /* webpackChunkName: "component---src-templates-notice-js" */),
  "component---src-templates-notice-list-js": () => import("./../../../src/templates/noticeList.js" /* webpackChunkName: "component---src-templates-notice-list-js" */)
}

